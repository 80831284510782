/** 政策服务 */
<template>
  <div :class="['policy', 'content', {'mt-30':$isPc}]">
    <section class="select-con justify-between align-center" :class="{'flex-wrap':!$isPc}">
      <div :class="['select-list', 'flex',{'flex-wrap':!$isPc}]">
        <div>
          <label class="select-label">类别</label>
          <el-radio-group v-model="filtersData.article_level" @change="handlefilter()">
            <el-radio label="国家">国家</el-radio>
            <el-radio label="北京市">北京市</el-radio>
            <el-radio label="经开区">经开区</el-radio>
          </el-radio-group>
        </div>
      </div>
      <Search backColor="#fff" @searchBtn="searchBtn" :class="{'pa-0':!$isPc}" />
    </section>
    <LoadMore :onLoadMore="onLoadMore" :loading="loading" :finished="finished">
      <List :data="policyData" :loading="loading" :total="pageTotal" :resetPage="resetPage" @page="changePage">
        <template v-slot="{item}">
          <router-link :to="{ path: '/article_details', query: { id: item.id, type: 'policy' } }">
            <div :class="['item-info', {'flex-1':$isPc}, 'flex-column', $isPc?'justify-between':'justify-around']">
              <div class="justify-between">
                <h4 class="item-title text-ellipsis-lines">
                  {{item.title}}
                </h4>
                <el-tag v-if="item.is_top==2" type="danger" effect="dark">
                  置顶
                </el-tag>
              </div>
              <div v-if="$isPc" class="item-info-text text-ellipsis-lines">
                {{$settleHtml(item.article_content)}}
              </div>
              <p class="item-info-desc">
                <span class="mr-30 text-ellipsis">
                  发布来源： {{item.article_unit}}
                </span>
                <span style="float:right">
                  发布时间：{{item.created_at|filterTime}}
                </span>
              </p>
            </div>
          </router-link>
        </template>
      </List>
    </LoadMore>
  </div>
</template>
<script>
import { getArticle } from '@/api/article'
import LoadMore from '@/components/LoadMore.vue'
import List from '@/components/List.vue'
import Search from '@/components/Search.vue'
export default {
  name: 'Policy',
  components: {
    LoadMore,
    List,
    Search
  },
  data () {
    return {
      filtersData: {
        article_type: '政策',
        article_level: '国家',
        keywords: '',
        page: 1
      },
      pageTotal: 0,
      policyData: [],
      loading: false,//正在加载
      finished: false, // 加载完成
      isReset: false,//是否重置数据
      resetPage: false,//分页重置
    }
  },
  async mounted () {
    this.onLoadMore()
  },
  methods: {
    async getPolicyData (filtersData) {
      try {
        let { data, total } = await getArticle(filtersData)
        this.policyData = this.$isPc ? data : [...this.policyData, ...data]
        this.pageTotal = total
        this.isReset = false
        this.loading = false;
      } catch (error) { }
    },
    // 筛选项切换
    handlefilter () {
      this.isReset = true
      this.onLoadMore()
    },
    // 搜索
    searchBtn (val) {
      this.filtersData.keywords = val
      this.isReset = true
      this.onLoadMore()
    },
    // 整合查询数据
    async setFiltersData () {
      if (this.isReset) {
        this.pageTotal = 0
        this.policyData = []
        this.filtersData.page = 1
        this.resetPage = !this.resetPage
        this.loading = true
        this.finished = false
      }
      if (!this.filtersData.keywords) {
        delete this.filtersData.keywords
      }
      let newFiltersData = JSON.parse(JSON.stringify(this.filtersData));

      await this.getPolicyData(newFiltersData)
    },
    // 切换分页
    changePage (page) {
      this.filtersData.page = page
      this.onLoadMore()
    },
    // 上拉加载
    async onLoadMore (done) {
      this.loading = true
      await this.setFiltersData()
      done && done();
      if (this.policyData.length >= this.pageTotal) {
        this.finished = true
        return
      }
      this.filtersData.page += 1
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/.select-con {
  padding: 20px;
  background-color: #eff3fa;
  .select-list {
    .select-label {
      font-size: 14px;
      color: #b3bcd0;
      margin-top: 2px;
    }
    .el-radio-group {
      .el-radio {
        margin-right: 0;
        .el-radio__input {
          visibility: hidden;
        }
        .el-radio__label {
          font-size: 16px;
        }
      }
    }
  }
}
.el-tag--danger {
  height: 20px;
  line-height: 18px;
  border-radius: 2px;
}
</style>